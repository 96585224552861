.cubes-table {
    .display-selector-container {
        display: flex;
        justify-content:flex-end;
        align-items: center;

        .filter-button {
            margin-left: 10px;
        }
    }

    .map-view {
        margin-top: -55px;
        margin-bottom: -30px;
    }

    .filter-container {
        position: absolute;
        top: 217px;
        right: 23px;
        background-color: #fff;
        flex-direction: row;
        width: 25%;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        max-height: 500px;
        overflow: auto;
    }
}